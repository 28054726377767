const FreePlayBtn = ({freePlay, freePlayAction, winner}) => {
    return (
        <div className={`gs-absolute gs-mx-autogs-absolute gs-z-50 ${winner ? '-gs-bottom-[168px] lg:-gs-bottom-[210px]' : '-gs-bottom-[154px] sm:-gs-bottom-[92px] lg:-gs-bottom-[116px]'} gs-w-full gs-flex gs-justify-center`}>
            <button onClick={() => freePlayAction()} className={`gs-chaloops gs-font-semibold gs-text-[14px] sm:gs-text-[18px] gs-text-white gs-border-2 gs-border-white gs-rounded-full gs-px-[14px] lg:gs-px-[28px] gs-py-[8px] sm:gs-px-[24px] md:gs-px-[32px] lg:gs-py-[12px] hover:gs-border-[#F48258] hover:gs-text-[#F48258]`}>
                Click to claim your free tickets
            </button>
        </div>
    )
}

export default FreePlayBtn;