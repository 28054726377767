export const SpendToPlaySVG = (gsClass) => {
    return (
        <svg className={gsClass} width="504" height="378" viewBox="0 0 504 378" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M248.451 178.14C385.666 165.017 496.902 119.819 496.902 77.1889C496.902 34.5587 385.666 10.6388 248.451 23.7625C111.235 36.8861 0 82.0836 0 124.714C0 167.344 111.235 191.264 248.451 178.14Z" fill="white"/>
            <path d="M400.631 337.036C407.314 318.987 404.479 299.004 397.93 281.406C396.558 277.311 394.555 273.079 392.979 269.051C406.415 288.157 413.323 315.905 400.653 337.037H400.631V337.036Z" fill="#030303"/>
            <path d="M58.2741 337.036C45.6041 315.905 52.4907 288.157 65.948 269.05C64.3735 273.079 62.3701 277.332 60.997 281.405C54.4254 299.003 51.6132 319.01 58.2741 337.036V337.036Z" fill="#030303"/>
            <path d="M155.74 265.136C154.751 262.93 180.067 274.993 190.419 266.441C200.771 257.866 198.363 257.821 199.286 242.631C200.186 227.441 190.442 178.877 231.827 172.486C272.043 166.297 275.485 202.056 278.253 213.241C278.253 213.241 277.061 212.634 265.696 212.994C254.332 213.354 259.17 231.919 264.504 242.227C274.293 261.152 273.236 306.342 228.406 310.55C192.445 313.903 171.177 300.018 155.74 265.159L155.739 265.136H155.74Z" fill="#FFDC74"/>
            <path d="M278.276 213.218L275.71 201.695C275.71 201.695 270.421 210.472 268.486 212.858L278.276 213.195V213.219V213.218Z" fill="#F5835A"/>
            <path d="M236.913 308.928C236.98 312.439 237.024 315.95 237.093 319.461C237.116 321.352 234.145 321.284 234.1 319.394C234.033 315.884 233.988 312.372 233.92 308.862C233.897 306.972 236.891 307.04 236.913 308.93V308.929V308.928ZM247.648 189.768C245.014 189.723 242.832 191.771 242.765 194.36C242.719 196.948 244.813 199.085 247.445 199.13C250.078 199.175 252.284 197.127 252.328 194.54C252.373 191.952 250.28 189.814 247.648 189.77V189.769V189.768ZM274.72 198.679C272.762 202.145 268.037 209.954 265.337 212.902C266.372 213.082 267.678 212.857 268.465 212.88C271.03 210.044 273.371 206.039 275.217 202.73C275.172 202.708 275.712 201.74 275.69 201.717C275.42 200.884 275.217 199.602 274.722 198.679H274.721H274.72ZM214.521 311.201C214.521 314.194 214.521 317.186 214.521 320.18C214.521 322.071 217.515 322.138 217.515 320.248C217.515 317.255 217.515 314.262 217.515 311.268C217.515 309.379 214.521 309.31 214.521 311.202V311.201Z" fill="black"/>
            <path d="M277.375 170.932C276.881 163.662 271.253 158.172 264.615 155.899C264.368 155.809 264.097 155.719 263.85 155.652C260.564 154.661 256.063 154.526 254.825 158.487C254.96 158.082 255.927 157.948 256.265 157.88C258.741 157.385 260.405 159.522 259.393 161.841C258.111 164.812 254.825 164.946 252.125 164.113C249.807 163.393 247.668 162.313 245.351 161.615C243.168 160.962 240.94 160.444 238.689 160.085C233.266 159.207 227.73 159.23 222.306 160.13C221.451 160.265 220.595 160.445 219.74 160.624C199.396 165.036 187.131 185.784 193.207 205.701C194.445 209.729 196.29 214.073 198.946 218.709C199.194 212.655 200.229 205.476 203.695 200.39C205.226 198.139 207.588 196.249 210.402 196.744C215.713 197.712 215.736 206.444 215.534 211.484C215.489 212.452 216.095 213.352 216.996 213.69L225.66 216.21C224.152 211.777 223.769 206.015 224.692 201.402C225.457 197.577 228.247 194.988 232.006 194.155C233.019 193.929 233.627 192.917 233.379 191.904L232.028 186.796C231.826 185.986 231.781 185.154 231.938 184.321C233.22 178.132 243.392 180.337 247.489 181.26C250.055 181.823 252.597 182.566 255.096 183.375C258.967 184.658 262.545 185.581 266.664 184.973C268.779 184.658 270.894 183.96 272.627 182.7C274.832 181.126 276.002 178.919 276.767 176.399C277.307 174.622 277.509 172.754 277.375 170.886L277.375 170.929V170.932Z" fill="black"/>
            <path d="M329.203 271.212C329.203 271.212 317.861 246.885 311.694 239.435C311.694 239.435 305.213 224.853 281.223 224.853L177.682 224.852C153.691 224.852 147.188 239.435 147.188 239.435C141.022 246.907 129.679 271.212 129.679 271.212L174.441 271.212L229.441 258.363L284.441 271.212H329.202L329.203 271.212Z" fill="#959697"/>
            <path d="M328.933 271.324C323.42 260.589 318.086 249.449 310.772 239.817C303.031 226.764 286.648 225.864 272.807 226.562L228.947 227.192V222.444H229.914V227.214C215.287 227.416 200.681 227.822 186.054 227.912C172.416 227.552 157.158 228.002 149.282 240.356C143.835 247.13 138.975 255.884 134.474 263.446L129.928 271.322L129.388 271.074C131.526 265.381 133.889 259.778 136.431 254.242C138.569 249.538 143.07 240.694 145.051 238.488C151.421 226.201 165.08 221.903 177.817 221.746C179.483 221.746 184.298 221.746 186.031 221.746C200.637 221.836 215.264 222.264 229.891 222.443V227.213L228.925 227.215V222.444L272.786 223.075C283.835 223.007 295.898 223.322 305.079 230.389C307.96 232.347 311.425 236.397 312.438 238.783C316.174 243.756 318.829 249.18 321.665 254.627C324.387 260.05 326.975 265.541 329.474 271.055L328.934 271.303V271.324L328.933 271.324Z" fill="black"/>
            <path d="M165.777 339.287C164.067 333.459 161.748 330.488 156.662 328.395L159.79 329.453C131.614 317.683 129.318 369.353 146.58 376.42C164.179 383.643 170.412 355.019 165.777 339.288V339.287Z" fill="black"/>
            <path d="M135.779 269.006C135.779 269.006 122.367 260.567 94.3028 256.089C91.9176 255.706 89.4872 255.863 87.1914 256.562C76.8623 259.645 52.6471 273.44 58.4757 334.764L135.801 333.729V269.007L135.779 269.006Z" fill="#35BFC0"/>
            <path d="M120.161 291.803L135.779 269.006C135.779 269.006 174.914 254.694 229.938 254.694V332.423L135.779 333.751L116.695 321.734L120.161 291.825V291.802V291.803Z" fill="#35BFC0"/>
            <path d="M103.35 282.103C103.282 281.968 103.193 281.832 103.102 281.72C98.9169 275.104 88.3621 275.757 83.3885 281.09C79.8332 284.893 79.0225 290.655 80.1926 295.695C81.0927 299.566 83.1637 303.324 86.5168 305.484C90.5451 308.072 96.1931 307.892 99.9975 304.989C104.161 301.816 105.713 296.191 105.579 290.947C105.488 287.864 104.882 284.737 103.328 282.103L103.351 282.104L103.35 282.103Z" stroke="black" strokeWidth="0.679636" strokeMiterlimit="2.61313" strokeLinejoin="round"/>
            <path d="M82.623 290.137C84.2435 293.985 90.4097 301.884 94.8885 300.444C96.2834 299.747 97.0707 297.563 97.746 295.561C98.1062 297.834 98.0611 300.399 96.036 302.109C89.869 306.026 81.9026 295.921 82.6456 290.115L82.623 290.137Z" fill="black"/>
            <path d="M129.703 274.61C118.361 287.01 111.879 302.988 109.336 319.438C108.931 321.801 108.751 324.208 108.301 326.616C108.053 324.208 107.85 321.755 107.828 319.324C107.986 302.356 115.344 284.353 129.703 274.631V274.609V274.61Z" fill="#030303"/>
            <path d="M105.601 278.638C95.5189 270.063 83.6813 264.887 73.3748 276.185L73.1942 274.205C73.5987 274.588 74.2072 275.105 74.7245 275.578C76.3667 276.995 78.1227 278.48 79.6755 280.034C78.2355 279.606 76.8398 279.043 75.4666 278.458C74.0266 277.806 72.7889 277.266 71.3932 276.366C70.8081 275.983 70.6727 275.195 71.0555 274.61C80.8221 260.319 97.925 265.068 105.577 278.661L105.601 278.637V278.638Z" fill="black"/>
            <path d="M293.129 339.287C294.839 333.459 297.157 330.488 302.243 328.395L299.115 329.453C327.29 317.683 329.586 369.353 312.325 376.42C294.727 383.643 288.494 355.019 293.129 339.288V339.287Z" fill="black"/>
            <path d="M323.127 269.006C323.127 269.006 336.54 260.567 364.603 256.089C366.989 255.706 369.418 255.863 371.737 256.562C382.066 259.645 406.281 273.44 400.452 334.764L323.127 333.729V269.007V269.006Z" fill="#35BFC0"/>
            <path d="M338.745 291.803L323.127 269.006C323.127 269.006 283.992 254.694 228.969 254.694V332.423L323.127 333.751L342.211 321.734L338.745 291.825V291.802V291.803Z" fill="#35BFC0"/>
            <path d="M355.555 282.103C355.623 281.968 355.713 281.832 355.803 281.72C359.988 275.104 370.543 275.757 375.517 281.09C379.073 284.893 379.883 290.655 378.713 295.695C377.813 299.566 375.742 303.324 372.389 305.484C368.361 308.072 362.713 307.892 358.909 304.989C354.745 301.816 353.193 296.191 353.327 290.947C353.418 287.864 354.025 284.737 355.578 282.103L355.555 282.104V282.103Z" stroke="black" strokeWidth="0.679636" strokeMiterlimit="2.61313" strokeLinejoin="round"/>
            <path d="M376.282 290.137C377.025 295.943 369.035 306.047 362.892 302.132C360.866 300.445 360.821 297.879 361.182 295.584C361.857 297.587 362.645 299.769 364.039 300.467C368.517 301.907 374.661 294.031 376.304 290.16L376.282 290.138V290.137Z" fill="black"/>
            <path d="M353.305 278.638C360.888 265.18 377.835 260.297 387.669 274.362C388.187 274.924 388.187 275.848 387.534 276.321C384.879 277.963 382.178 279.066 379.23 280.011C380.76 278.481 382.538 276.973 384.181 275.555C384.699 275.082 385.328 274.565 385.711 274.183L385.531 276.163C375.223 264.887 363.386 270.064 353.305 278.616V278.639V278.638Z" fill="black"/>
            <path d="M92.8629 315.747L78.5055 315.904C76.6827 315.792 75.1299 317.187 75.017 318.987C74.9051 320.788 76.2991 322.363 78.1001 322.476L94.1684 323.421C95.9911 323.533 97.544 322.138 97.6568 320.338C97.7688 318.538 94.6631 315.86 92.8412 315.747H92.8629Z" fill="black"/>
            <path d="M382.629 315.432L368.293 316.265C366.47 316.265 364.985 317.705 364.963 319.527C364.941 321.35 366.403 322.835 368.225 322.858L384.338 323.038C386.161 323.038 387.646 321.598 387.668 319.776C387.668 317.953 384.45 315.455 382.65 315.432L382.628 315.431L382.629 315.432Z" fill="black"/>
            <path d="M200.996 266.642C210.156 270.535 218.662 275.555 227.057 280.82H224.829C231.13 276.927 237.702 273.618 244.701 271.211C247.042 270.445 249.405 269.747 251.834 269.321L239.345 276.792C235.271 279.29 229.892 282.486 225.954 285.074C217.267 279.448 208.716 273.665 201.02 266.688L200.996 266.642Z" fill="#030303"/>
            <path d="M310.862 333.189C308.094 321.081 300.285 304.361 300.285 304.361C244.34 295.067 161.276 303.574 161.276 303.574C161.276 303.574 150.587 326.596 151.464 333.19H310.862V333.189Z" fill="#C6C6C6"/>
            <path d="M310.862 333.819C308.297 323.939 304.2 314.465 299.88 305.239L300.375 305.621C260.227 299.951 219.292 302.246 178.964 304.632C173.18 304.903 167.374 305.262 161.635 305.69L162.85 304.811C158.71 314.151 153.106 323.512 151.846 333.752L151.463 333.797C151.103 331.097 151.734 328.463 152.296 325.898C154.254 317.932 157.201 310.302 160.419 302.764C183.846 299.816 207.43 298.916 231.015 299.096C254.262 299.276 277.621 300.267 300.598 304.294L300.959 304.361C303.097 309.177 305.032 314.038 306.832 318.967C308.565 323.805 310.163 328.688 311.265 333.729L310.882 333.82H310.861L310.862 333.819Z" fill="black"/>
            <path d="M394.128 330.353C378.105 332.446 338.812 333.121 301.658 333.211L229.937 332.693V332.694H229.464H228.991L157.27 333.212C120.115 333.122 80.8229 332.446 64.8006 330.355C56.0912 329.207 51.8381 328.622 52.0403 336.183C52.1072 338.365 57.3507 345.838 59.6014 347.84C64.2599 352.004 111.271 354.052 157.27 354.344C164.876 354.389 172.46 354.389 179.819 354.344H279.108C286.467 354.389 294.051 354.389 301.657 354.344H301.658C347.678 354.052 394.668 352.003 399.326 347.84C401.577 345.837 406.842 338.388 406.888 336.183C407.069 328.621 402.815 329.207 394.128 330.355V330.354V330.353Z" fill="#C6C6C6"/>
            <path d="M394.128 330.353C398.179 330.151 404.187 327.878 406.393 332.266C407.563 335.169 406.707 338.207 404.929 340.66C403.354 343.158 401.667 345.611 399.573 347.749C398.402 348.785 396.873 349.077 395.478 349.482C389.694 350.81 383.776 351.35 377.902 351.913C329.787 355.806 267.091 355.109 218.279 355.829C168.432 355.965 116.785 358.17 67.2518 352.499C64.4838 352.026 61.603 351.576 59.0155 350.272C55.797 347.976 54.0193 344.69 51.9718 341.675C50.8243 339.762 49.6091 337.827 49.7211 335.306C49.6994 323.738 62.4362 328.194 69.5694 328.622C87.1453 330.198 104.878 330.49 122.567 330.895C146.287 331.638 204.438 331.592 228.968 331.66L227.955 332.673C227.955 332.673 227.955 331.66 227.955 331.638C228.832 331.638 230.048 331.638 230.926 331.66V332.65C230.926 332.65 229.913 331.66 229.936 331.66L271.006 332.357C312.032 332.875 353.215 333.797 394.128 330.332V330.354V330.353ZM394.128 330.353C353.418 334.224 311.896 333.256 271.006 333.593L229.936 333.683H228.946V332.67C228.946 332.648 229.959 333.683 229.959 333.66H228.991C228.991 333.66 230.027 332.647 230.004 332.67V333.66C187.764 334.313 128.667 335.956 86.9891 334.11C81.0702 333.863 75.1288 333.571 69.1658 333.031C64.89 332.783 60.5241 331.681 56.4506 331.995C54.3127 332.085 54.3353 334.246 54.3127 335.866C55.3257 339.197 58.6336 343.292 60.906 345.814C62.346 346.939 65.9021 347.367 67.9948 347.772C94.1908 351.327 120.88 351.485 147.323 352.138L289.213 353.511C324.567 353.421 360.372 354.591 395.388 349.19C400.069 348.56 402.319 344.059 404.772 340.548C406.505 338.117 407.473 335.169 406.28 332.289C404.322 327.947 397.795 330.197 394.105 330.331L394.129 330.353H394.128Z" fill="black"/>
            <path d="M64.7771 330.353C60.8165 330.172 54.6954 327.923 52.5576 332.288C51.4101 335.169 52.265 338.162 54.0427 340.592C56.4739 344.124 58.7463 348.648 63.45 349.3C98.4438 354.769 134.294 353.688 169.648 354.161C219.292 353.464 334.447 355.759 382.403 350.831C386.791 350.357 391.202 349.863 395.501 348.872C396.873 348.468 398.404 348.175 399.304 347.23C401.307 345.16 403.018 342.707 404.57 340.254C406.257 337.846 407.068 335.056 405.875 332.31C403.962 327.899 393.566 331.365 389.56 331.252C360.057 333.525 330.441 333.322 300.87 333.503C300.668 333.503 229.891 332.895 229.712 332.895C229.712 332.895 229.712 332.693 229.712 332.67L229.914 332.872L228.946 332.873C230.341 332.873 187.898 333.12 187.875 333.12C146.85 333.165 105.645 334.065 64.7537 330.329L64.7763 330.352L64.7771 330.353ZM64.7771 330.353C105.667 333.977 146.873 332.964 187.899 332.828C214.432 332.334 272.943 332.671 300.893 332.896C330.441 332.649 360.057 332.784 389.515 330.421C393.947 330.128 398.337 328.935 402.837 329.341C404.345 329.476 406.056 330.376 406.663 331.929C407.968 334.9 407.113 338.208 405.335 340.684C403.737 343.227 402.049 345.656 399.934 347.84C398.832 348.943 397.189 349.325 395.725 349.731C391.382 350.766 386.926 351.238 382.515 351.689C335.166 356.438 218.774 353.985 169.669 354.48C134.315 353.94 98.443 354.907 63.4491 349.349C58.7689 348.719 56.4496 344.15 54.0201 340.594C52.3093 338.164 51.3198 335.126 52.5567 332.245C54.56 327.879 61.0856 330.152 64.7988 330.31L64.7771 330.354V330.353Z" fill="black"/>
            <path d="M169.378 309.536C206.127 309.671 257.685 308.568 293.895 309.536V309.762C273.19 311.517 252.397 311.472 231.648 311.067C211.034 310.594 189.565 310.775 169.4 309.559L169.379 309.536H169.378Z" fill="black"/>
            <path d="M162.986 318.898C203.876 319.033 261.195 317.93 301.478 318.898V319.124C278.434 320.88 255.322 320.835 232.232 320.429C209.3 319.956 185.491 320.137 162.987 318.921L162.986 318.898Z" fill="black"/>
            <path d="M161.366 326.144C203.404 326.279 262.344 325.176 303.751 326.144V326.37C280.054 328.125 256.29 328.08 232.549 327.675C208.964 327.202 184.524 327.383 161.345 326.167L161.367 326.144H161.366Z" fill="black"/>
            <path d="M179.527 258.631C224.963 249.449 272.875 252.578 317.232 265.81C332.197 271.369 342.167 285.794 347.118 300.422C349.818 308.907 351.056 317.796 350.629 326.617C350.358 317.818 348.829 309.065 345.7 300.918C339.804 285.029 327.449 269.839 310.054 266.643C267.655 255.616 222.985 252.758 179.529 258.609L179.527 258.631Z" fill="#030303"/>
            <path d="M322.069 268.961C334.739 260.499 349.929 258.406 364.603 256.089C357.424 257.596 350.245 259.329 343.313 261.715C336.675 264.056 329.585 266.734 324.162 271.212L322.047 268.984L322.07 268.962L322.069 268.961Z" fill="#030303"/>
            <path d="M135.779 270.087C122.681 263.403 108.099 260.927 94.3027 256.089C101.752 256.111 109.133 257.417 116.266 259.51C123.266 261.783 130.646 264.574 135.8 270.087H135.779Z" fill="#030303"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M362.416 97.5121L316.972 209.761L452.028 110.988L362.416 97.5121Z" fill="white"/>
            <path d="M132.145 53.6369C168.061 43.9363 209.499 36.3987 253.622 32.1785C390.837 19.0554 502.073 42.9746 502.073 85.605C502.073 109.996 465.649 135.226 408.788 154.809L322.142 218.178L340.253 173.446C325.315 176.601 309.699 179.389 293.564 181.744C280.561 183.641 267.219 185.257 253.622 186.557M166.4 190.009C72.2106 188.044 5.17139 166.226 5.17139 133.13C5.17139 118.203 18.8092 102.962 42.4229 88.8929" stroke="#44BBC3" strokeWidth="2.99977" strokeMiterlimit="2.61313"/>
        </svg>
    )
}