import { Spotlight, SpotlightMobile } from '../assets/SpotlightSVG';
import { smallGradient, largeGradient } from '../assets/Gradients';

const Spotlights = () => {
    return (
        <div className='gs-relative gs-mx-auto'>
            { smallGradient(`gs-absolute gs-top-0 gs-left-0 gs-z-0 -gs-mt-12 -gs-ml-16 lg:-gs-mt-20 lg:-gs-ml-28 gs-w-[186px] gs-h-[186px] lg:gs-w-auto lg:gs-h-auto`) }
            { Spotlight('gs-hidden md:gs-block gs-absolute -gs-top-[1.35rem] gs-left-0 sm:-gs-left-44 md:gs-left-1 lg:gs-left-4 gs-z-40') }
            { Spotlight('gs-hidden md:gs-block gs-absolute -gs-top-[1.35rem] gs-right-0 sm:-gs-right-44 md:gs-right-1 lg:gs-right-4 gs-z-40 gs-transform -gs-scale-x-100')}

            { largeGradient(`gs-absolute gs-top-0 gs-right-0 gs-z-0 -gs-mt-2 -gs-mr-28 lg:-gs-mt-48 lg:-gs-right-36 gs-w-[313px] gs-h-[313px] lg:gs-w-auto lg:gs-h-auto`) }
            { SpotlightMobile('md:gs-hidden gs-absolute gs-top-0 -gs-left-2 gs-z-40 -gs-mt-5') }
            { SpotlightMobile('md:gs-hidden gs-absolute gs-top-0 -gs-right-2 gs-z-40 -gs-mt-5 gs-transform -gs-scale-x-100') }
        </div>
    )
}

export default Spotlights;