export async function getGrandPrizeEntries(shopifyCustomerId, baseUrl, apiToken) {
  const response = await fetch(`${baseUrl}/api/customers/${shopifyCustomerId}/grand_prize`, {
    headers: {
      'Client-Token': `${apiToken}`
    }
  });

  const result = await response.json();
  return result.data[0].entries;
}

export async function getUnclaimedRewards(shopifyCustomerId, baseUrl, apiToken) {
  const response = await fetch(`${baseUrl}/api/customers/${shopifyCustomerId}/rewards?claimed=0`, {
    headers: {
      'Client-Token': `${apiToken}`
    }
  });
  const result = await response.json();
  return result;
}

export async function getClaimedRewards(shopifyCustomerId, baseUrl, apiToken, nextClaimedUrl = null) {
  if (nextClaimedUrl === null) nextClaimedUrl = `${baseUrl}/api/customers/${shopifyCustomerId}/rewards?claimed=1`;
  const response = await fetch(nextClaimedUrl, {
    headers: {
      'Client-Token': `${apiToken}`
    }
  });
  const result = await response.json();
  return result;
}

export async function claimReward(shopifyCustomerId, baseUrl, apiToken, reward) {
  const response = await fetch(`${baseUrl}/api/campaigns/${reward.prize.campaign_id}/customers/${shopifyCustomerId}/codes/${reward.prize.code}`, {
    method: 'PUT',
    headers: {
      'Client-Token': `${apiToken}`
    }
  });
  const result = await response.json();
  return result;
}

export async function newCHUserID(shopifyCustomerId) {
  const response = await fetch(`https://hub.creativity.inc/api/v1/authentication/user`, {
    method: 'PUT',
    headers: {
      'Client-Token': '7BgMRMT2PvvYRmvaJDZHpdvSfloLCCNm',
      'Client-Version': '1.0.0',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ user_remote_id: `${shopifyCustomerId}` })
  });
  const result = await response.json()
  return result;
}

export async function postFreePlay(baseUrl, apiToken, campaignId, shopifyCustomerId, shopifyCustomerEmail) {
  const formData = new FormData();
  formData.append("email", shopifyCustomerEmail);
  const response = await fetch(`${baseUrl}/api/campaigns/${campaignId}/customers/${shopifyCustomerId}/free_plays`,
    {
      method: 'POST',
      headers: {
        'Client-Token': apiToken
      },
      body: formData
    }
  )
  const result = await response.json();
  return result;  
}

export async function getFreePlay(baseUrl, apiToken, campaignId, customerShopifyId) {
  const response = await fetch(`${baseUrl}/api/campaigns/${campaignId}/customers/${customerShopifyId}/free_plays`,
    {
      headers: {
        'Client-Token': apiToken
      }
    }
  )
  const result = await response.json();
  return result;
}