import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const el = document.getElementById('creativity-sweepstakes-app');
const attributes = el.dataset;

/*
  Required 'attributes'
  // let attributes = {
  //   shopifyCustomerId:, // passed as a data attribute in the liquid template
  //   shopifyCustomerEmail: // passed to claim free plays
  //   baseUrl: ,
  //   chUserId:,
  //   apiToken:,
  //   spendToPlay: 'Get a ticket to play for Every $10 you spend'
  //   imageAnimatedBox: '', // asset url to animated image
  //   imageMask: '', // asset url to desktop mask image
  //   imageMaskMobile: '' // asset url to mobile mask image
  // }
*/

attributes.apiToken = (typeof attributes.apiToken !== 'undefined') ? attributes.apiToken : 'bXNxYw==';
attributes.chUserId = (typeof attributes.chUserId !== 'undefined') ? attributes.chUserId : '';
attributes.spendToPlay = (typeof attributes.spendToPlay !== 'undefined') ? attributes.spendToPlay : 'Get a ticket to play for Every $10 you spend';

if(process.env.NODE_ENV === 'development') {
  console.log(process.env)
  attributes.shopifyCustomerId = (typeof attributes.shopifyCustomerId !== 'undefined') ? attributes.shopifyCustomerId : 3901382230051;
  attributes.shopifyCustomerEmail = (typeof attributes.shopifyCustomerEmail !== 'undefined') ? attributes.shopifyCustomerEmail : 'jaitch@gmail.com';
  attributes.imageAnimatedBox = 'https://dev-preview.missouriquiltco.com/cdn/shop/t/43/assets/gs-animated-box.gif?v=56671425760675266741724945816';
  attributes.imageMask = 'https://dev-preview.missouriquiltco.com/cdn/shop/t/43/assets/gs-frame.svg?v=84543391919042152921725034904'
  attributes.imageMaskMobile = 'https://dev-preview.missouriquiltco.com/cdn/shop/t/43/assets/gs-frame-mobile.svg?v=89417167798736086751725052435'
}


if(typeof attributes.baseUrl === 'undefined') {
  const currentHost = window.location.hostname;
  const base_api_path = 'creativity-sweepstakes.apps.creativity.inc';
  let api_host = currentHost.includes('dev') ? 'staging' : 'production';
  if(currentHost.includes('local')) api_host = 'staging'

  switch (api_host) {
    case 'staging':
      api_host = `https://staging.${base_api_path}`;
      break;
    case 'production':
      api_host = `https://production.${base_api_path}`;
      break;
    default:
      api_host = 'http://localhost:9887';
  }
  attributes.baseUrl = api_host
}

const root = ReactDOM.createRoot(el);

root.render(
  <App {...attributes} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
