export const goldenTicketCounterSVG = (gsClass) => {
    return (
        <>
            <svg className={`${gsClass} gs-hidden lg:gs-block`} fill="none" viewBox="0 0 148 159" xmlns="http://www.w3.org/2000/svg"><path d="m50.906 2.9183 79.583 4.678-9.514 127.29-63.276 4.676-6.7927-136.64z" fill="#000" stroke="#000" strokeMiterlimit="2.6131" strokeWidth="2.9994"/><path d="m2.5724 20.418 84.604 4.6771-10.114 127.29-67.269 4.676-7.2212-136.64z" fill="#000" stroke="#000" strokeMiterlimit="2.6131" strokeWidth="2.9994"/><path d="m66.473 2.0441 79.583 4.3996-9.514 119.73-59.732 4.152-0.2325 8.872-63.276 4.399-6.7927-128.53 60.91 4.8922-0.9468-17.916z" fill="#EE5097" stroke="#fff" strokeMiterlimit="2.6131" strokeWidth="2.9994"/><path d="m67.42 19.96 0.9765 11.906m6.479 78.955 1.8536 22.593" stroke="#fff" strokeMiterlimit="2.6131" strokeWidth="2.9994"/></svg>
            <svg className={`${gsClass} lg:gs-hidden`} width="77" height="82" viewBox="0 0 77 82" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.403 2.5293L66.5832 4.89112L61.7794 69.1566L29.8326 71.5174L26.403 2.5293Z" fill="black" stroke="black" strokeWidth="2.99942" strokeMiterlimit="2.61313"/><path d="M2 11.365L44.7154 13.7263L39.6088 77.9918L5.64585 80.3527L2 11.365Z" fill="black" stroke="black" strokeWidth="2.99942" strokeMiterlimit="2.61313"/><path d="M34.2621 2.08813L74.4423 4.30945L69.6389 64.761L39.4812 66.8573L39.3639 71.3366L7.41706 73.5574L3.98755 8.6637L34.7402 11.1337L34.2621 2.08813Z" fill="#EE5097" stroke="white" strokeWidth="2.99942" strokeMiterlimit="2.61313"/></svg> 
        </>
    )
}

export const ticketCounterSVG = (gsClass) => {
    return (
        <>
            <svg className={`${gsClass} gs-hidden lg:gs-block`} fill="none" viewBox="0 0 153 159" xmlns="http://www.w3.org/2000/svg"><path d="m97.22 7.5684-79.583 4.677 9.5139 127.29 63.276 4.676 6.7927-136.64z" fill="#000" stroke="#000" strokeMiterlimit="2.6131" strokeWidth="2.9994"/><path d="m150.55 20.067-84.605 4.678 10.114 127.29 67.269 4.676 7.221-136.64z" fill="#000" stroke="#000" strokeMiterlimit="2.6131" strokeWidth="2.9994"/><path d="m81.654 1.694-79.583 4.3987 9.5138 119.73 59.732 4.153 0.2314 8.872 63.277 4.399 6.793-128.53-60.911 4.8913 0.9469-17.915z" fill="#FFC52E" stroke="#fff" strokeMiterlimit="2.6131" strokeWidth="2.9994"/><path d="m80.707 19.609-0.9766 11.906m-6.4789 78.955-1.8536 22.593" stroke="#fff" strokeMiterlimit="2.6131" strokeWidth="2.9994"/></svg>
            <svg className={`${gsClass} lg:gs-hidden`} width="79" height="82" viewBox="0 0 79 82" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M50.0728 5.05396L9.89267 7.4153L14.6961 71.6808L46.6433 74.0416L50.0728 5.05396Z" fill="black" stroke="black" strokeWidth="2.99942" strokeMiterlimit="2.61313"/><path d="M77 11.3645L34.2847 13.7263L39.3913 77.9918L73.3542 80.3527L77 11.3645Z" fill="black" stroke="black" strokeWidth="2.99942" strokeMiterlimit="2.61313"/><path d="M42.2137 2.08813L2.03357 4.30898L6.83695 64.7605L36.9946 66.8573L37.1115 71.3366L69.0588 73.5574L72.4883 8.6637L41.7357 11.1332L42.2137 2.08813Z" fill="#FFC52E" stroke="white" strokeWidth="2.99942" strokeMiterlimit="2.61313"/><path d="M41.7356 11.1333L41.2426 17.1444M37.9715 57.0073L37.0356 68.4142" stroke="white" strokeWidth="2.99942" strokeMiterlimit="2.61313"/></svg>
        </>
    )
}