export const Spotlight = (gsClass) => {
    return (
        <svg className={gsClass} xmlns="http://www.w3.org/2000/svg" width="540" height="604" fill="none" viewBox="0 0 540 604"><path fill="#000" d="M27.92 195.466c-6.25-2.87-15.348 3.584-20.317 14.406-4.97 10.828-3.93 21.928 2.322 24.798l33.83 15.528 17.996-39.209L27.92 195.46v.006Z"/><path fill="#000" d="M14.611 197.759c-3.895-1.789-9.56 2.231-12.655 8.977-3.094 6.744-2.447 13.662 1.444 15.447l21.074 9.672 11.212-24.424-21.075-9.672Z"/><path fill="#fff" d="M43.742 250.184c-6.25-2.87-7.29-13.973-2.319-24.798 4.97-10.826 14.066-17.276 20.317-14.406 6.251 2.87 7.29 13.972 2.32 24.798-4.97 10.826-14.067 17.275-20.318 14.406Z"/><path stroke="#000" strokeMiterlimit="2.613" strokeWidth="5" d="M38.675 177.706H20.176a7.525 7.525 0 0 0-7.522 7.522v28.367h33.538v-28.367a7.525 7.525 0 0 0-7.522-7.522h.005ZM29.426 177.706V.792"/><path fill="url(#a)" fillRule="evenodd" d="m48.929 230.951 490.423 62.827L408.566 603.96 48.929 230.951Z" clipRule="evenodd"/><defs><linearGradient id="a" x1="55" x2="337.227" y1="223" y2="396.773" gradientUnits="userSpaceOnUse"><stop stopColor="#fff"/><stop offset="1" stopColor="#fff" stopOpacity="0"/></linearGradient></defs></svg>
    )
}

export const SpotlightMobile = (gsClass) => {
    return (
        <svg className={gsClass} width="286" height="357" viewBox="0 0 286 357" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M39.9667 102.892L276.753 201.336L168.935 338.657L39.9667 102.892Z" fill="url(#paint0_linear_4790_8435)"/><path d="M47.6074 94.7052C44.8862 92.399 39.4411 94.3516 35.4472 99.063C31.4525 103.777 30.4218 109.467 33.1434 111.773L47.8722 124.255L62.3374 107.184L47.6082 94.7024L47.6075 94.7048L47.6074 94.7052Z" fill="black"/><path d="M40.6399 93.9919C38.944 92.5539 35.5529 93.7705 33.0648 96.7076C30.5772 99.6444 29.9343 103.19 31.6285 104.625L40.8038 112.4L49.8152 101.766L40.6399 93.9919Z" fill="black"/><path d="M47.8675 124.246C45.1462 121.939 46.1784 116.249 50.173 111.536C54.1677 106.823 59.612 104.872 62.3334 107.178C65.0547 109.485 64.0225 115.175 60.0279 119.888C56.0332 124.601 50.5889 126.552 47.8675 124.246Z" fill="white"/><path d="M48.9972 85.2076H42.3467C40.8547 85.2076 39.6422 86.7916 39.6422 88.7458V102.088H51.6999V88.7458C51.6999 86.7939 50.4892 85.2076 48.9955 85.2076H48.9972Z" stroke="black" stopWidth="3" stopMiterlimit="2.61313"/><path d="M45.2692 85.2076V0" stroke="black" stopWidth="3" stopMiterlimit="2.61313"/><defs><linearGradient id="paint0_linear_4790_8435" x1="44.0908" y1="99.7437" x2="161.552" y2="225.249" gradientUnits="userSpaceOnUse"><stop stopColor="white"/><stop offset="1" stopColor="white" stopOpacity="0"/></linearGradient></defs></svg>
    )
}