const smallGradient = (gsClass) => {
    return (
        <svg className={gsClass} xmlns="http://www.w3.org/2000/svg" width="342" height="342" fill="none" viewBox="0 0 342 342"><path fill="#413B51" fillRule="evenodd" d="M170.6 0c94.221 0 170.601 76.38 170.601 170.6 0 94.221-76.38 170.601-170.601 170.601C76.38 341.201 0 264.821 0 170.6 0 76.38 76.38 0 170.6 0Z" clipRule="evenodd"/><path fill="#474058" fillRule="evenodd" d="M170.601 24.844c80.499 0 145.756 65.256 145.756 145.756s-65.256 145.757-145.756 145.757S24.844 251.101 24.844 170.6C24.844 90.101 90.1 24.844 170.6 24.844Z" clipRule="evenodd"/><path fill="#4D465F" fillRule="evenodd" d="M170.6 49.688c66.779 0 120.913 54.134 120.913 120.912 0 66.779-54.134 120.913-120.913 120.913-66.778 0-120.912-54.134-120.912-120.913 0-66.778 54.134-120.912 120.912-120.912Z" clipRule="evenodd"/><path fill="#534C67" fillRule="evenodd" d="M170.601 74.533c53.056 0 96.068 43.011 96.068 96.068 0 53.057-43.011 96.069-96.068 96.069-53.057 0-96.069-43.011-96.069-96.069 0-53.057 43.011-96.068 96.069-96.068Z" clipRule="evenodd"/><path fill="#5B536F" fillRule="evenodd" d="M170.6 99.377c39.336 0 71.224 31.888 71.224 71.223 0 39.336-31.888 71.224-71.224 71.224-39.335 0-71.223-31.888-71.223-71.224 0-39.335 31.888-71.223 71.223-71.223Z" clipRule="evenodd"/><path fill="#625A78" d="M170.6 216.98c25.615 0 46.381-20.765 46.381-46.38 0-25.614-20.766-46.38-46.381-46.38-25.614 0-46.38 20.766-46.38 46.38 0 25.615 20.766 46.38 46.38 46.38Z"/></svg>
    )
}

const largeGradient = (gsClass) => {
    return (
        <svg className={gsClass} xmlns="http://www.w3.org/2000/svg" width="632" height="632" fill="none" viewBox="0 0 632 632"><path fill="#413B51" fillRule="evenodd" d="M315.633 0c174.32 0 315.633 141.312 315.633 315.633 0 174.32-141.312 315.633-315.633 315.633C141.313 631.266 0 489.954 0 315.633 0 141.313 141.312 0 315.633 0Z" clipRule="evenodd"/><path fill="#474058" fillRule="evenodd" d="M315.633 45.965c148.934 0 269.668 120.733 269.668 269.668 0 148.934-120.733 269.669-269.668 269.669-148.934 0-269.668-120.734-269.668-269.669 0-148.934 120.733-269.668 269.668-269.668Z" clipRule="evenodd"/><path fill="#4D465F" fillRule="evenodd" d="M315.633 91.93c123.549 0 223.704 100.154 223.704 223.703S439.182 539.337 315.633 539.337 91.93 439.182 91.93 315.633 192.084 91.93 315.633 91.93Z" clipRule="evenodd"/><path fill="#534C67" fillRule="evenodd" d="M315.633 137.895c98.163 0 177.739 79.575 177.739 177.738s-79.576 177.739-177.739 177.739c-98.163 0-177.738-79.576-177.738-177.739 0-98.163 79.575-177.738 177.738-177.738Z" clipRule="evenodd"/><path fill="#5B536F" fillRule="evenodd" d="M315.633 183.86c72.777 0 131.774 58.996 131.774 131.773 0 72.777-58.997 131.774-131.774 131.774S183.86 388.41 183.86 315.633 242.856 183.86 315.633 183.86Z" clipRule="evenodd"/><path fill="#625A78" d="M315.633 401.442c47.391 0 85.809-38.418 85.809-85.809s-38.418-85.808-85.809-85.808-85.809 38.417-85.809 85.808c0 47.391 38.418 85.809 85.809 85.809Z"/></svg>
    )
}

export { smallGradient, largeGradient }