import parse from 'html-react-parser';
import { goldenTicketCounterSVG, ticketCounterSVG } from '../assets/CounterSVG';

const Counter = ({dir, text, count}) => {
    const countDisplay = (c) => {
        let count = c;
        if (c <= 9) count = `<span className='${dir === 'left' ? 'gs-text-[#EE5097]' : 'gs-text-[#FFC52E]'} sm:gs-text-[52px] md:gs-text-[72px] lg:gs-text-[82px] gs-text-stroke-white gs-w-[24px] sm:gs-w-[28px] md:gs-w-auto lg:gs-w-auto'>0</span><span className="gs-w-[24px] md:gs-w-auto">${c}</span>`;
        
        if(c > 99) {
            return `<div className='gs-flex gs-items-center gs-justify-center gs-text-[42px] sm:gs-text-[52px] md:gs-text-[72px] lg:gs-text-[82px]'>${count}</div>`;
        }else{
            return `<div className='gs-flex gs-items-center gs-justify-center gs-text-[42px] sm:gs-text-[52px] md:gs-text-[72px] lg:gs-text-[82px]'>${count}</div>`;
        }
    }

    const orderClass = (d) => {
        return (d === 'left') ? `gs-order-2 lg:gs-order-1` : `gs-order-2`;
    }

    const orderClassAlt = (d) => {
        return (d === 'left') ? `gs-order-1 lg:gs-order-2` : `gs-order-1`;
    }
    

    return(
        <div className={`gs-flex gs-flex-col lg:gs-flex-row lg:gs-justify-start gs-gap-[4px] ${dir === 'left' ? 'lg:-gs-mx-[48px]' : 'lg:-gs-mx-[64px]'}`}>
            <div className={`${orderClass(dir)} gs-w-min ${dir === 'left' ? 'gs-ml-[10px]' : ' gs-mr-[10px] gs-self-end'} lg:gs-self-auto`}>
                <div className={`gs-flex ${dir === 'left' ? 'gs-justify-end' : 'gs-justify-start'} gs-items-center gs-h-full`}>
                    <span className={`gs-text-white gs-montserrat ${dir === 'left' ? 'gs-text-left lg:gs-text-right' : 'gs-text-right lg:gs-text-left'} gs-font-bold gs-w-full gs-text-[15px] lg:gs-text-[25px] gs-leading-[20px] lg:gs-leading-[24px]`}>{ text }</span>
                </div>
            </div>

            <div className={`gs-relative ${orderClassAlt(dir)}`}>
                {
                    dir === 'left'
                    ? goldenTicketCounterSVG('gs-object-cover gs-w-[72px] gs-h-[78px] sm:gs-w-[88px] sm:gs-h-auto md:gs-w-[118px] lg:gs-w-[144px] lg:gs-h-[155px]')
                    : ticketCounterSVG('gs-object-cover gs-w-[75px] gs-h-[78px] sm:gs-w-[88px] sm:gs-h-auto md:gs-w-[118px] lg:gs-w-[148px] lg:gs-h-[155px]')
                } 
                <div className={`counter gs-flex-1 gs-absolute gs-left-0 gs-right-0 gs-top-0 gs-bottom-0 m-auto gs-text-white gs-hwt-artz gs-tracking-[8px] sm:gs-tracking-[6px] gs-font-black gs-flex gs-items-center gs-justify-center`}>
                    <div className='-gs-mt-[8px] lg:-gs-mt-[16px] lg:-gs-m-r-[5px] gs-textBlack-shadow'>
                        { 
                            parse(countDisplay(count))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Counter;