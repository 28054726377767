import Reward from './Reward';

export default function History({ items }) {
    return (
        <div className='gs-container gs-mx-auto gs-flex gs-flex-col gs-justify-center gs-mt-[64px] gs-max-w-[362px] sm:gs-max-w-[550px] md:gs-max-w-[660px] lg:gs-max-w-[886px] xl:gs-max-w-[886px]'>
            <div className='gs-text-center gs-text-lightYellow gs-font-bold lg:gs-text-[32px] gs-uppercase'>You're A Winner</div>
            <div className='gs-text-center gs-text-white gs-text-[18px] gs-montserrat'>
                All rewards will be redeemed<br className='sm:gs-hidden' /> at the end of the promotion
                <div className='gs-text-[12px]'><sup>*</sup>Digital Patterns will be available in my account pages 24 hours after winning.</div>
            </div>

            <div className='gs-mx-[16px] gs-my-[16px] sm:gs-m-[48px] gs-grid gs-grid-cols-2 sm:gs-grid-cols-3 gs-gap-[32px] lg:gs-gap-[64px]'>
                {
                    items && items.map((item, key) => (
                    (  
                        <Reward reward={item} key={key} />
                    )
                    ))
                }
            </div>
        </div>
    )
}