import { ChuckSVG } from "../assets/ChuckSVG"

const Chuck = () => {
    return(
        <div id="chuck" className="gs-absolute gs-z-50 gs-mx-auto gs-mt-[24px] sm:gs-mt-[36px] md:gs-mt-[42px] lg:gs-mt-[48px] gs-w-full gs-flex gs-justify-center">
            { ChuckSVG('gs-w-[174px] gs-h-[168px] sm:gs-w-auto sm:gs-h-[236px] md:gs-w-auto md:gs-h-[292px] lg:gs-w-[440px] lg:gs-h-[425px] gs-ml-[24px] sm:gs-ml-[48px] md:gs-ml-[54px] lg:gs-ml-[88px]') }
        </div>
    )
}

export default Chuck;