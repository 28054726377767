import FAQBanner from '../assets/FAQBanner';
import FAQDisplay from '../widget/FAQDisplay';

const FAQ = () => {
    return (
        <div id="gs_faq" className="gs-container gs-bg-[#FBF9E7] gs-relative gs-rounded-xl gs-mt-[128px] gs-py-[32px] gs-px-[24px] sm:gs-px-[36px] md:gs-px-[54px] lg:gs-px-[96px] gs-mx-auto gs-max-w-[362px] sm:gs-max-w-[550px] md:gs-max-w-[660px] lg:gs-max-w-[886px] xl:gs-max-w-[886px]">
            { FAQBanner(`gs-w-[256px] sm:gs-w-[296px] md:gs-w-[328px] lg:gs-w-[356px] gs-h-auto -gs-mt-[84px] gs-mb-[16px] gs-mx-auto`) }

            {
                faqs.map((i,k) => (
                    <FAQDisplay q={i} key={k} />
                )) 
            }

            <div>
                <a href="/pages/missouri-star-quilt-company-golden-star-2024-promotion-official-rules" className='gs-underline gs-text-[#F48258]'>
                    MISSOURI STAR QUILT COMPANY GOLDEN STAR 2024 PROMOTION OFFICIAL RULES
                </a>
            </div>
        </div>
    )
}

export default FAQ;

const faqs = [
    {
        q: "How do I enter?",
        a: "With every $10 increment purchased, you are automatically entered into the drawing for the grand prize AND you will also gain game play for the chance to win a prize. You can view your redeemable gameplays on your account under the Golden Star link .  You must be logged in to see your gameplays and to play the game."
    },
    {
        q: "How do I send in a mail-in entry?",
        a: "Those who wish to participate without making a purchase may request a free digital game entry by sending an addressed, hand-written postcard, including: Name, Mailing Address, and a valid Email Address, to: Missouri Star Golden Star, P.O. Box 210, Hamilton, MO 64644. Vermont residents can exclude return postage. All requests must be postmarked by March 31, 2023, and received no later than October 2, 2024.  "
    },
    {
        q: "How do I know how many entries I have? ",
        a: "<div className='gs-block gs-mb-[8px]'>Grand prize drawing</div>For every $10 spent, you get one entry into the grand prize drawing. This means that if you spend $50 total in one order, you get 5 entries into the drawing. You can find your game play page on your account. <div className='gs-my-[8px]'>Earning other prizes</div> For every $10 spent, you also get one redeemable gameplay. You can view the number of redeemable gameplays on your account under the Golden Star link. Play for the chance to win a quilty prize!"
    },
    {
        q: "How will I be notified if I'm a winner?",
        a: "The lucky winners of the grand prize drawing will be notified via email or phone call by Missouri Star. Winners from the gameplay will be automatically notified as they redeem their gameplay on their account."
    },
    {
        q: "When will I get my prize(s)? ",
        a: "If you win a physical prize you will see a zero dollar order come through your account you can track. Your physical prize will ship within 10 business days of winning. <div className='gs-block gs-my-[8px]'>If you win a digital prize it will be added to your account within 72 hrs of winning.</div> If you win a Golden Star Gift Card you will receive it via email  on Oct 1st . You have until October 31st at 11:59pm to spend your Golden Star Card."
    },
    {
        q: "How do I get the Official Rules/Winners' List ?",
        a: "A copy of the prize winners or the official rules and odds of winning may be requested in writing by sending a self-addressed and stamped envelope to: Missouri Star Quilt Company Golden Star, 1005 S Hughes Street, Hamilton, MO 64644."
    },
    {
        q: "Can I switch my prizes?",
        a: "Unfortunately, all prizes are final. There can be no substitutions. You do have the option to decline physical prizes if they don’t tickle your fancy. Please also note that digital prizes (such as digital patterns) can not be given as gifts and will automatically be attached to your account."
    },
    {
        q: "What is the grand prize?",
        a: "<div className='gs-block gs-mb-[8px]'>This year we don't have just one grand prize but three!</div><div className='gs-block gs-mb-[16px]'>Grand Prize 1: Trip for 2 to Hamilton, MO in March 2025 (round trip economy air transportation for two (2) to Kansas City International Airport, ground transportation from airport to Hamilton, MO and a four (4) day/three (3) night stay at a local hotel of Missouri Star's choosing); $2500 Missouri Star gift card; Lunch with Natalie & Misty; video studio tour; lead quilt walk with Doans; </div><div className='gs-block gs-mb-[16px]'>Grand Prize 2: Sewing Room Makeover (Includes $2400 Sponsor gift card. Kangaroo & Joey Sewing Cabinet, Millie Cutting & Ironing Table, Hydraulic Sewing Chair - Sew Wow!, Bernina Machine (540E), and  50 Missouri Star Patterns)</div><div className='gs-block gs-mb-[4px]'>Grand Prize 3: Sewing Room Mini Makeover (Includes $1500 Sponsor gift card for fabric and Bernina Machine (540E))</div>"
    },
]