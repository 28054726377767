import parse from 'html-react-parser';
import { Icon1 } from '../assets/Icons';
import { useState } from 'react';

const FAQ = (props) => {
    const { q } = props; 
    const [showMore, setShowMore] = useState(false);
    const answer = showMore ? parse(q.a) : parse(q.a.slice(0, 128));

    return (
        <div className={`gs-mb-[32px]`} >
            <div onClick={() => setShowMore(!showMore)} className="gs-cursor-pointer gs-chaloops gs-font-semibold gs-text-[18px] md:gs-text-[24px] gs-mb-[8px] gs-tracking-widest gs-flex gs-justify-start gs-items-center gs-gap-[8px]">
                {Icon1(`gs-w-[24px] gs-h-auto`)} {q.q}
            </div>
            <div className={`gs-montserrat`}>
                {answer}
                <div onClick={() => setShowMore(!showMore)} className='gs-cursor-pointer gs-inline-block'>
                    <span className='gs-underline gs-text-[#F48258]'>
                    {
                        showMore ? 'read less' : '...read more'
                    }
                    </span>
                </div>
            </div>
        </div>
    )
}

export default FAQ;