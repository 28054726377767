import React from 'react';
import parse from 'html-react-parser';

import { WinnerRibbon, WinnerTitle } from '../assets/ButtonsSVG';

const PlayButton = (props) => {
    const { ticketClicker, winner, winnerToggle, ticketCount, reward, playStatus } = props;
    const rewardTitle = (t) => {
        if (t.includes('Digital Download')){
            let clean_title = t.split('-');
            let patternTitle = clean_title[1].split('by')[0];
            return `${clean_title[0].trim()}<br />${patternTitle}`;
        }
        return t;
    }

    return (
        <div className={`gs-absolute gs-z-50 ${!winner ? '-gs-bottom-[88px] sm:-gs-bottom-[0] lg:-gs-bottom-[24px]' : 'lg:-gs-bottom-[144px]'} gs-w-full gs-flex gs-justify-center`}>
            {
                !winner && (
                    <button type="button" onClick={() => ticketClicker()} disabled={playStatus} className='gs-mx-auto gs-cursor-pointer gs-rounded-full gs-bg-[#D62027] hover:gs-bg-[#FEC823] gs-border-[4px] md:gs-border-[8px] gs-border-white gs-group gs-black-box-shadow'>
                        <span className='gs-px-[36px] lg:gs-px-[58px] lg:gs-py-[0px] gs-chaloops gs-text-white gs-uppercase gs-font-semibold gs-text-[36px] sm:gs-text-[48px] md:gs-text-[60] lg:gs-text-[72px] group-hover:gs-text-black'>Play</span>
                    </button>
                )
            }
            {
                winner && (
                    <div className="gs-flex gs-flex-col gs-items-center">
                        <div className='gs-relative gs-mt-[172px] sm:gs-mt-[248px] md:gs-mt-[300px]'>
                            { WinnerTitle(`gs-relative gs-z-50 gs-mx-auto -gs-mb-[36px] lg:-gs-mb-[48px] -gs-mt-[8px] gs-z-50 gs-w-[105px] sm:gs-w-[118px] md:gs-w-[136px] lg:gs-w-[190px] gs-h-[62px]`) }
                            { WinnerRibbon(`gs-w-[319px] sm:gs-w-[348px] md:gs-w-[412px] lg:gs-w-[578px]`) }
                            {
                                reward?.prize && (
                                    <div className='gs-absolute gs-inset-x-0 gs-top-0 gs-right-0 gs-text-center gs-mt-[48px] gs-ml-[54px] lg:gs-mt-[56px] lg:gs-ml-[74px] gs-w-[220px] sm:gs-w-[250px] md:gs-w-[306px] lg:gs-w-[432px] gs-overflow-hidden'>
                                        <span className='gs-montserrat gs-font-bold gs-text-[18px] md:gs-text-[] lg:gs-text-[32px] gs-leading-[28px] gs-text-white'>{ parse(rewardTitle(reward.prize.name)) }</span>
                                    </div>
                                )
                            }
                        </div>
                        {
                            ticketCount > 0 && (
                                <button className='gs-relative gs-z-50 gs-chaloops gs-text-white gs-font-semibold gs-text-[14px] sm:gs-text-[18px] md:gs-text-[22px] lg:gs-text-[26px] gs-rounded-full gs-border-[3px] gs-py-[8px] gs-px-[22px] hover:gs-border-[#F48258] hover:gs-text-[#F48258]' onClick={() => winnerToggle(false)}>
                                    Play Again
                                </button>
                                
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default PlayButton;