import { SpendToPlaySVG } from "../assets/SpendToPlaySVG";

const SpendToPlayMsg = (props) => {
    return (
        <div className='gs-absolute gs-z-50 gs-mx-auto gs-top-0 gs-left-0 gs-right-0 gs-bottom-0 gs-flex gs-justify-center gs-items-center'>
            <div className="gs-absolute gs-montserrat gs-font-bold gs-text-[12px] sm:gs-text-[18px] md:gs-text-[24px] gs-w-[163px] gs-h-[35px] sm:gs-w-[224px] md:gs-w-[300px] -gs-mt-[88px] gs-ml-6 sm:-gs-mt-[132px] md:-gs-mt-[172px] lg:gs-text-[32px] lg:gs-leading-[40px] lg:gs-w-[373px] lg:gs-h-[78px] lg:-gs-mt-[192px] lg:gs-ml-auto gs-text-[#D62027] gs-tracking-normal">{props.spendTag}</div>
            { SpendToPlaySVG(`gs-ml-6 gs-w-[219px] gs-h-[165px] -gs-mt-[8px] sm:gs-w-[300px] sm:gs-h-auto md:gs-w-[384px] lg:gs-w-[502px] lg:gs-h-[377px]`) }
        </div>
    )
 }

export default SpendToPlayMsg;